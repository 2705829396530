<div class="auth">
  <div class="left">
    <form class="auth-wrapper" [formGroup]="form" (submit)="submitted = true; send()">
      <span class="auth-title" [ngSwitch]="pageType">
        <ng-container *ngSwitchCase="'login'">Sign in to CybeReady</ng-container>
        <ng-container *ngSwitchCase="'set-password'">Create a Password</ng-container>
        <ng-container *ngSwitchCase="'reset-password'">Enter your email to reset password</ng-container>
        <ng-container *ngSwitchCase="'2fa'">Please enter verification code</ng-container>
        <ng-container *ngSwitchCase="'change-password'">Change Password</ng-container>
      </span>
      <div class="auth-inputs ui-layout-column" [ngSwitch]="pageType">
        <ng-container *ngSwitchCase="'login'">
          <input matInput
                 [ngClass]="{'error': form.controls.email.invalid && form.controls.email.touched}"
                 formControlName="email" type="email" placeholder="Email address">
          <span class="input-error"
                [ngClass]="{'error-visible': form.controls.email.invalid && form.controls.email.touched}">
            <ng-container *ngIf="!form.controls.email.value; else correctEmail">Please enter your email address</ng-container>
            <ng-template #correctEmail>Please fill in a correct email address</ng-template>
            </span>
          <div class="password-row">
            <input matInput
                   [ngClass]="{'error': form.controls.password.invalid && form.controls.password.touched}"
                   formControlName="password" [type]="showPassword ? 'text' : 'password'" placeholder="Password">
            <img class="show-password-icon pointer" [src]="showPassword ? 'assets/svg/eye_login_hidden.svg' : 'assets/svg/eye_login.svg'" (click)="showPassword = !showPassword">
          </div>
          <span class="input-error"
                [ngClass]="{'error-visible': form.controls.password.invalid && form.controls.password.touched}">Please enter your password</span>
        </ng-container>

        <ng-container *ngSwitchCase="'set-password'">

          <div class="password-row">
            <input matInput formControlName="password" [type]="showPassword ? 'text' : 'password'" placeholder="Create a password">
            <img class="show-password-icon pointer" [src]="showPassword ? 'assets/svg/eye_login_hidden.svg' : 'assets/svg/eye_login.svg'" (click)="showPassword = !showPassword">
          </div>
          <div class="password-row">
            <input matInput
                   [ngClass]="{'error': form.controls.validatePassword.invalid && form.controls.validatePassword.touched}"
                   formControlName="validatePassword" placeholder="Confirm password" [type]="showValidatePassword ? 'text' : 'password'">
            <img class="show-password-icon pointer" [src]="showValidatePassword ? 'assets/svg/eye_login_hidden.svg' : 'assets/svg/eye_login.svg'" (click)="showValidatePassword = !showValidatePassword">
          </div>

          <div class="validation ui-layout-column">
            <p>Password must include at least:</p>
            <p [ngClass]="{'red': !validationError.character && validationDone,
            'green': validationError.character && validationDone}">
              <img *ngIf="validationDone" [src]="validationError.character ? 'assets/svg/correct.svg' : 'assets/svg/incorrect.svg'"
                   alt="" class="margin-r-10"
                   >8 characters</p>
            <p [ngClass]="{'red': !validationError.upperCase && validationDone,
            'green': validationError.upperCase && validationDone}">
              <img *ngIf="validationDone" [src]="validationError.upperCase ? 'assets/svg/correct.svg' : 'assets/svg/incorrect.svg'"
                   alt="" class="margin-r-10"
              >1 uppercase letter</p>
            <p [ngClass]="{'red': !validationError.number && validationDone,
            'green': validationError.number && validationDone}">
              <img *ngIf="validationDone" [src]="validationError.number ? 'assets/svg/correct.svg' : 'assets/svg/incorrect.svg'"
                   alt="" class="margin-r-10"
              >1 number</p>
            <p [ngClass]="{'red': !validationError.specialCharacter && validationDone,
            'green': validationError.specialCharacter && validationDone}">
              <img *ngIf="validationDone" [src]="validationError.specialCharacter ? 'assets/svg/correct.svg' : 'assets/svg/incorrect.svg'"
                   alt="" class="margin-r-10"
              >1 special character</p>
            <p *ngIf="form.controls.validatePassword.touched && form.errors?.passwordMismatch && validationDone" [ngClass]="{'red':
            form.controls.validatePassword.touched && form.errors?.passwordMismatch && validationDone,
            'green':
            !form.controls.validatePassword.touched && form.errors?.passwordMismatch && validationDone}">
              <img *ngIf="validationDone" [src]="form.controls.validatePassword.touched && !form.errors?.passwordMismatch && validationDone ? 'assets/svg/correct.svg' : 'assets/svg/incorrect.svg'"
                   alt="" class="margin-r-10"
              >Password doesn’t match</p>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'change-password'">

          <div class="password-row">
            <input matInput formControlName="oldPassword" [type]="showPassword ? 'text' : 'password'" placeholder="Old password">
            <img class="show-password-icon pointer" [src]="showPassword ? 'assets/svg/eye_login_hidden.svg' : 'assets/svg/eye_login.svg'" (click)="showPassword = !showPassword">
          </div>

          <div class="password-row">
            <input matInput formControlName="newPassword" [type]="showPassword ? 'text' : 'password'" placeholder="New password">
            <img class="show-password-icon pointer" [src]="showPassword ? 'assets/svg/eye_login_hidden.svg' : 'assets/svg/eye_login.svg'" (click)="showPassword = !showPassword">
          </div>

          <input matInput
                 [ngClass]="{'error': form.controls.retypeNew.invalid && form.controls.retypeNew.touched}"
                 formControlName="retypeNew" type="password" placeholder="Confirm password">

          <div class="validation ui-layout-column">
            <p>Password must include at least:</p>
            <p [ngClass]="{'red': !validationError.character && validationDone,
            'green': validationError.character && validationDone}">
              <img *ngIf="validationDone" [src]="validationError.character ? 'assets/svg/correct.svg' : 'assets/svg/incorrect.svg'"
                   alt="" class="margin-r-10"
              >8 characters</p>
            <p [ngClass]="{'red': !validationError.upperCase && validationDone,
            'green': validationError.upperCase && validationDone}">
              <img *ngIf="validationDone" [src]="validationError.upperCase ? 'assets/svg/correct.svg' : 'assets/svg/incorrect.svg'"
                   alt="" class="margin-r-10"
              >1 uppercase letter</p>
            <p [ngClass]="{'red': !validationError.number && validationDone,
            'green': validationError.number && validationDone}">
              <img *ngIf="validationDone" [src]="validationError.number ? 'assets/svg/correct.svg' : 'assets/svg/incorrect.svg'"
                   alt="" class="margin-r-10"
              >1 number</p>
            <p [ngClass]="{'red': !validationError.specialCharacter && validationDone,
            'green': validationError.specialCharacter && validationDone}">
              <img *ngIf="validationDone" [src]="validationError.specialCharacter ? 'assets/svg/correct.svg' : 'assets/svg/incorrect.svg'"
                   alt="" class="margin-r-10"
              >1 special character</p>
            <p *ngIf="form.controls.newPassword.touched && form.errors?.passwordMismatch && validationDone" [ngClass]="{'red':
            form.controls.retypeNew.touched && form.errors?.passwordMismatch && validationDone,
            'green':
            !form.controls.retypeNew.touched && form.errors?.passwordMismatch && validationDone}">
              <img *ngIf="validationDone" [src]="form.controls.retypeNew.touched && !form.errors?.passwordMismatch && validationDone ? 'assets/svg/correct.svg' : 'assets/svg/incorrect.svg'"
                   alt="" class="margin-r-10"
              >Password doesn’t match</p>
          </div>

        </ng-container>

        <ng-container *ngSwitchCase="'reset-password'">
          <input matInput
                 [ngClass]="{'error': form.controls.email.invalid && form.controls.email.touched}"
                 formControlName="email" type="email" placeholder="Email address">
          <span class="input-error"
                [ngClass]="{'error-visible': form.controls.email.invalid && form.controls.email.touched}">
            <ng-container *ngIf="!form.controls.email.value; else correctEmail">Please enter your email address</ng-container>
            <ng-template #correctEmail>Please fill in a correct email address</ng-template>
            </span>
        </ng-container>

        <ng-container *ngSwitchCase="'2fa'">
          <input matInput
                 [ngClass]="{'error': form.controls.tfaCode.invalid && form.controls.tfaCode.touched}"
                 formControlName="tfaCode" type="email" placeholder="Verification Code">
          <span class="input-error"
                [ngClass]="{'error-visible': form.controls.tfaCode.invalid && form.controls.tfaCode.touched}">Please enter your Two Factor Authentication code</span>
        </ng-container>
      </div>
      <div class="auth-actions" [ngSwitch]="pageType">
        <ng-container *ngSwitchCase="'login'">
          <div class="ui-layout-row ui-layout-space-between">
            <span class="error-text">{{errorText}}</span>
            <a class="forgot-action pointer" [routerLink]="['/', 'forgot-password']">Forgot password?</a>
          </div>
          <button class="dashboard-button high-primary">Log in <img src="assets/svg/arrow_right.svg" class="arrow" alt=""></button>
        </ng-container>
        <ng-container *ngSwitchCase="'set-password'">
          <button class="dashboard-button high-primary set-password-create">Create <img src="assets/svg/arrow_right.svg" class="arrow" alt=""></button>
        </ng-container>
        <ng-container *ngSwitchCase="'change-password'">
          <a class="forgot-action pointer" [routerLink]="['/', 'login']">Cancel</a>
          <button class="dashboard-button high-primary set-password-create">Save <img src="assets/svg/arrow_right.svg" class="arrow" alt=""></button>
        </ng-container>
        <ng-container *ngSwitchCase="'reset-password'">
          <a class="forgot-action pointer" [routerLink]="['/', 'login']">Cancel</a>
          <button class="dashboard-button high-primary">Reset Password</button>
        </ng-container>
        <ng-container *ngSwitchCase="'2fa'">
          <div class="ui-layout-row ui-layout-space-between align-baseline">
            <mat-checkbox color="primary"
                          class="dashboard-checkbox tfa-control" formControlName="persist">Remember this device</mat-checkbox>
            <a class="forgot-action pointer" [routerLink]="['/', 'login']">Resend code</a>
          </div>
          <button class="dashboard-button high-primary">Log in <img src="assets/svg/arrow_right.svg" class="arrow" alt=""></button>
        </ng-container>
      </div>
    </form>

  </div>
  <div class="right">
    <img src="assets/svg/splashes.svg" alt="" class="splashes">
    <img src="assets/svg/cybeready-logo.svg" alt="" class="top-logo">
    <div class="graph-1">
      <span class="title">Increased <br> Phishing Detection</span>
      <img src="assets/gif/phishing_detection.gif" class="graph-1-icon">
    </div>
    <div class="graph-2">
      <span class="title">Risk Group Reduction</span>
      <img src="assets/gif/risk_reduction.gif" alt="" class="graph-2-icon">
    </div>
    <div class="graph-3">
      <span class="title">Complete<br>Workforce<br>Training</span>
      <img src="assets/svg/graph_3.svg" alt="" class="graph-3-icon">
    </div>
  </div>
</div>
