import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-send-phone-dialog',
  templateUrl: './send-phone-dialog.component.html',
  styleUrls: ['./send-phone-dialog.component.scss']
})

export class SendPhoneDialogComponent implements OnInit {

  phoneCtrl = new UntypedFormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      chips: Array<string>,
      removable: boolean | true,
      cancelText: string | null,
      confirmText: string | null,
      message: string | null,
      title: string | null,
    },
    private dialogRef: MatDialogRef<SendPhoneDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  public cancel() {
    this.close(false);
  }

  public close(value) {
      this.dialogRef.close(value);
  }

  public confirm() {
      this.close(this.data);
  }

  remove(chip: string): void {
    const index = this.data.chips.indexOf(chip);

    if (index >= 0) {
      this.data.chips.splice(index, 1);
    }
  }

  @HostListener('keydown.esc')
  public onEsc() {
    this.close(false);
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    this.data.chips.push(value.trim());
    /* TODO: Add phone number validation */
    /*if (this.campaignsService.validateEmail(value)) {
      this.data.chips.push(value.trim());
    } else {
      this.snackBar.open('Please enter valid email address');
    }*/

    if (input) {
      input.value = '';
    }

    this.phoneCtrl.setValue(null);
  }
}
