import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { UiHttp } from '@shared/services/ui-http/ui-http.service';
import { UserService } from '@shared/services/user/user.service';
import { map, tap } from 'rxjs/operators';
import {
  Bucket,
  CampaignsResponse,
  CreateCustomSimulationResponse, GetCustomSimulationSettingsMapped,
  GetCustomSimulationSettingsResponse
} from '@desktop/campaigns/campaigns.interface';
import { User } from '@app/auth/store/auth.interface';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class CampaignsService {

  constructor(private http: UiHttp,
              private store: Store,
              private userService: UserService) {
  }

  public approvedStatus = 2;
  public disapprovedStatus = 3;
  public bucketChanged: BehaviorSubject<Bucket> = new BehaviorSubject<Bucket>(null);

  getCampaignsNew(cp: string, q, campaign_type: string): Observable<CampaignsResponse> {
    return this.http.get('campaigns/', { cp, campaign_type, ...q}).pipe(
      map((response: CampaignsResponse) => {
        const { testing, ...rest } = response;
        return this.transformTitle(rest);
      })
    );
  }

  getBucketTranslation(bucketId: string, localeCode: string, user: User) {
    return this.http.get(`campaigns/bucket/${bucketId}/${localeCode}/translate/`, {cp: user.selected_customer.cp_id}).pipe(map(resp => {
      if (user.selected_customer.demo &&
        user.selected_customer.hasOwnProperty('edited_bucket_ces')) {
        Object.keys(user.selected_customer.edited_bucket_ces).forEach(ce => {
          const parts = ce.split('|');
          const changedBullet = parts[0];
          const tag = parts[1];
          const locale = parts[2];
          if (changedBullet === bucketId && locale === localeCode) {
            resp.changed_elements[tag] = user.selected_customer.edited_bucket_ces[ce];
            resp[tag] = user.selected_customer.edited_bucket_ces[ce];
          }
        });
      }
      return resp;
    }));
  }

  getAttackPagePreview(bucketId, localeCode, pageType) {
    const data = {cp: this.userService.userData.selected_customer.cp_id};
    return this.http.get(`campaigns/bucket/${bucketId}/${localeCode}/preview/${pageType}/`, data);
  }

  setBucketInclude(bucketId: string, include: boolean, cp: string): Observable<{error?: string}> {
    return this.http.post(`campaigns/bucket/${bucketId}/include/`, { cp, include });
  }

  setCampaignStatus(status: number, cp: string): Observable<{error?: string}> {
    return this.http.post('campaigns/status/', { cp, status });
  }

  pauseCampaign(pause_type: string, cp: string): Observable<any> {
    return this.http.post('campaigns/pause/', { cp, pause_type });
  }

  resumeCampaign(cp: string): Observable<any> {
    return this.http.post('campaigns/resume/', { cp });
  }

  sendTestCampaign(bucket_ids, emails, bucketTestNumbers?) {
    const data = {
      cp: this.userService.userData.selected_customer.cp_id,
      bucket_ids: bucket_ids,
      emails: emails,
      bucket_test_numbers: bucketTestNumbers,
    };
    return this.http.post('campaign/send-test/', data);
  }

  askContact() {
    if (this.userService.userData.demo) {
      return of({});
    } else {
      return this.http.post('contact-me/', {
        cp: this.userService.userData.selected_customer.cp_id,
      });
    }
  }

  testAttack(bucketId, emails, currentLocale) {
    const data = {
      cp: this.userService.userData.selected_customer.cp_id,
      emails: emails,
      locale: currentLocale,
    };
    return this.http.post(`campaigns/bucket/${bucketId}/attack-test/`, data);
  }

  validateEmail(val) {
    const email_pattern = /[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return !!val.match(email_pattern);
  }

  setAutopilot(autopilot_enabled: boolean, cp: string): Observable<{}> {
    return this.http.post('campaigns/', { cp, autopilot_enabled });
  }

  getAttachment(bucketId, localeCode) {
    const data = {
      cp: this.userService.userData.selected_customer.cp_id
    };
    return this.http.get(`campaigns/bucket/${bucketId}/${localeCode}/attachment/`, data, true);
  }

  changeBucketCEText(bucket, tag, textType, text, localeCode, campaignType, user: User, create_translations: boolean = false) {
    const data = {
      cp: user.selected_customer.cp_id,
      tag: tag,
      text_type: textType,
      text: text,
      locale: localeCode,
      create_translations,
    };
    if (user.selected_customer.demo && data.tag !== 'body') {
      if (data.text_type === 'custom') {
        bucket.translations[data.locale].changed_elements[tag] = text;
        bucket.translations[data.locale][tag] = text;
      } else {
        bucket.translations[data.locale].changed_elements[tag] = '';
        bucket.translations[data.locale][tag] = bucket.translations[data.locale].original_elements[tag];
      }
      return of(bucket);
    }

    if (data.tag === 'body') {
      data.text = btoa(unescape(encodeURIComponent(data.text)));
    }

    return this.http.post(`campaigns/bucket/${bucket.id}/edit_content_element/`, data).pipe(
      map((editedBucket) => {
        if (bucket.currentLocale === this.userService.userData.selected_customer.locale && tag === 'subject') {
          if (data.text_type === 'custom') {
            editedBucket.defaultLocaleSubject = text;
          } else {
            editedBucket.defaultLocaleSubject = bucket.translations[data.locale].original_elements[tag];
          }
          this.bucketChanged.next({ ...editedBucket});
        }
        return editedBucket;
      })
    );
  }

  shuffleBucket(bucket: Bucket, campaignType: string): Observable<any> {
    return this.http.post(`campaigns/bucket/${bucket.id}/shuffle/`, {
      cp: this.userService.userData.selected_customer.cp_id,
      branding: this.userService.userData.selected_customer.selected_branding
    });
  }

  renderTabTitle(campaign): string {
    if (campaign.cycle_details.length > 4) {
      return `${campaign.cycle_details[0].title} - ... - ${campaign.cycle_details.slice(-1)[0].title}`;
    }

    return Array.from(campaign.cycle_details, x => x['title']).join(' - ');
  }

  renderTitle(campaign): string {
    let title = '';
    if (campaign.cycle_details.length > 0) {
      title = campaign.cycle_details[0]['title'];
      if (campaign.cycle_details.length > 1) {
        title += ' - ' +  campaign.cycle_details.slice(-1)[0].title;
      }
    }
    return title;
  }

  transformTitle(response: CampaignsResponse): CampaignsResponse {
    const keys = ['suggested', 'active', 'completed'];
    for (const key of Object.keys(response)) {
      if (response.hasOwnProperty(key) && response[key]) {
        const campaign = response[key];
        if (keys.includes(key)) {
          response[key].tabTitle = this.renderTabTitle(campaign);
          response[key].title = this.renderTitle(campaign);
        }
        if (campaign && campaign.cycle_details) {
          response[key].cycleDetailsString = Array.from(
            response[key].cycle_details, x => `${x['title']}: ${x['start_date']} - ${x['end_date']}`).join('\n');
        }
      }
    }
    return response;
  }

  removeBucket(cp: string, bucket_id: string): Observable<any> {
    return this.http.post('campaign/remove_attack_from_campaign/', { cp, bucket_id });
  }

  changeDisplayOrLoginName(id: string, tag: string, text_type: string, text: string, locale: string, campaignType: string, cp: string): Observable<any> {
    return this.http.post(`campaigns/bucket/${id}/edit_content_element/`, {cp, tag, text_type, text, locale});
  }

  createCustomSimulation(cp: string, data, campaign_id: number): Observable<CreateCustomSimulationResponse> {
    delete data.locales;
    delete data.headers;
    delete data.sub_headers;
    delete data.sender_domains;
    delete data.suspicious_signs;
    data.video == '' ? data.video = null : data.video;
    data.simulation_body = btoa(unescape(encodeURIComponent(data.simulation_body)));
    return this.http.post('custom_simulation/', { cp, ...data, campaign_id });
  }

  getCustomSimulation(cp: string, id: number, campaign_id: number, locale_id: number): Observable<GetCustomSimulationSettingsResponse> {
    return this.http.get(`custom_simulation/${id}/`, { cp, campaign_id, locale_id });
  }

  editCustomSimulation(cp: string, id: number, data: any, campaign_id: number, locale_id: number): Observable<GetCustomSimulationSettingsResponse> {
    delete data.locales;
    delete data.headers;
    delete data.sub_headers;
    delete data.sender_domains;
    delete data.suspicious_signs;
    data.video == '' ? data.video = null : data.video;
    data.simulation_body = btoa(unescape(encodeURIComponent(data.simulation_body)));
    return this.http.patch(`custom_simulation/${id}/`, {cp, ...data, campaign_id, locale_id});
  }

  getCustomSimulationSettingsData(cp: string): Observable<GetCustomSimulationSettingsMapped> {
    return this.http.get(`custom_simulation/settings_data/`, { cp })
      .pipe(
      map((response: GetCustomSimulationSettingsResponse) => {
        response['sender_domains'] = response['sender_domains'].map((domain) => ({...domain, display_value: domain.name}));
        response['headers'] = response['headers'].map((header) => ({...header, display_value: header.value}));
        response['sub_headers'] = response['sub_headers'].map((subheader) => ({...subheader, display_value: subheader.value}));
        response['videos'] = response['videos'].map((video) => ({...video, display_value: video.name}));
        response['videos'] = [{id: '', name: '(No video)', display_value: '(No video)', value: 'no_video'},...response['videos']];
        response['suspicious_signs'] = this.transformSuspiciousSignsData(response['suspicious_signs']);
        return response;
      })
    );
  }

  getCustomSimulationLP(cp: string): Observable<string> {
    return this.http.get('custom_simulation/lp_preview/', { cp });
  }


  transformSuspiciousSignsData(data): GetCustomSimulationSettingsMapped['suspicious_signs'] {
    return data.map((item) => ({
      id: item.bullet_title_id,
      name: item.bullet_title_name,
      value: item.bullet_title_value,
      children: item.associated_bullet_title.map((child) => ({
        child_id: child.bullet_text_id,
        child_name: child.bullet_text_name,
        child_value: child.bullet_text_value,
      })),
    }));
  }

}
