<div class="dialog-close" cdkFocusInitial (click)="dialogRef.close()"></div>

<h1 mat-dialog-title>
  <ng-container *ngIf="data.customTitle; else defaultTitle">{{data.customTitle}}</ng-container>
  <ng-template #defaultTitle>Edit content</ng-template>
</h1>

<div id="edit-dialog" mat-dialog-content>
  <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="data.textType">

    <mat-radio-button (click)="focusTextarea(originalTextArea)" value="default" color="primary">
      Use default text
    </mat-radio-button>

    <textarea #originalTextArea
              class="default-text"
              [(ngModel)]="data.defaultText"
              [readOnly]="true"
              (click)="data.textType = 'default'"
              [ngClass]="{'rtl': data.rtl }"></textarea>

    <mat-radio-button (click)="focusTextarea(customTextArea)" value="custom" color="primary">
      Use custom text
    </mat-radio-button>

    <textarea #customTextArea
              class="custom-text"
              placeholder="Type here..."
              [maxlength]="data.maxLength"
              [(ngModel)]="data.customText"
              [readOnly]="data.textType==='default'"
              (click)="data.textType = 'custom'"
              [ngClass]="{'rtl': data.rtl }"></textarea>
  <div *ngIf="data.textType === 'custom' && !inputIsValid() && data.isSenderLoginName" class="error-message">
      The sender login name must not contain spaces, non-ASCII characters, or be empty.
  </div>
    <div *ngIf="data.textType === 'custom' && !inputIsValid() && data.smishingValidation" class="error-message">
      You can only have one phishing link in a smishing simulation.
    </div>
  </mat-radio-group>

  <div class="cab-change-notice">
    <span *ngIf="data.customText && data.textType === 'default'">
      * Selecting to revert to default text will delete the custom text
    </span>
  </div>

  <div class="language-changes ui-layout-row align-center" *ngIf="!data.showAutoTranslateCheckBox">
    <img src="/assets/svg/info-warning.svg">
    <p>Note that changes will apply only to the selected language</p>
  </div>
  <div class="ui-layout-row align-center margin-b-16" *ngIf="data.showAutoTranslateCheckBox">
    <mat-checkbox class="dashboard-checkbox"
                  [(ngModel)]="autoTranslateCheckBoxState"
                  name="autotranslate"
                  color="primary">
      Auto-translate text to all available languages
    </mat-checkbox>
  </div>

</div>

<div mat-dialog-actions class="ui-layout cab-change-bottom" ui-layout-align="end">
  <div>
    <button mat-button class="dashboard-button action" (click)="close()">Cancel</button>
    <button mat-button [mat-dialog-close]="{'text': data.customText, 'textType': data.textType, 'auto_translate': autoTranslateCheckBoxState}"
                       [disabled]="(data.textType === 'custom' && !inputIsValid())
                       || (data.textType === 'custom' && !data.customText?.length)
                       || (data.customText?.length > data.maxLength)"
                       class="dashboard-button confirm focused">
      Save
      <ng-container *ngIf="data.showAutoTranslateCheckBox && autoTranslateCheckBoxState"> & Translate</ng-container>
    </button>
  </div>
</div>

