import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '@shared/services/user/user.service';
import { Location } from '@angular/common';
import { FormAction } from '@shared/interfaces/FormAction';
import { SnackBarComponent } from '@shared/components/snack-bar/snack-bar.component';
import { forceLogout } from '@app/auth/store/auth.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-change-password',
  templateUrl: '../form.html',
  styleUrls: ['../form.scss']
})
export class ChangePasswordComponent {

  public form: UntypedFormGroup;
  public exclude: string[];
  public pageTitle: string;
  public formActionBtns: FormAction[];
  public submitted: boolean;
  public pageType: string = 'change-password';
  public errorText: string = '';
  public showPassword: boolean = false;
  public allValidationPassed = false;
  public showValidatePassword: boolean = false;
  public validationError = {
    character: false,
    number: false,
    upperCase: false,
    specialCharacter: false,
  };
  public validationDone = false;

  constructor(private fb: UntypedFormBuilder,
              private router: Router,
              private userService: UserService,
              private location: Location,
              private route: ActivatedRoute,
              private store: Store,
              private snackBar: SnackBarComponent,
              ) {
    this.pageTitle = 'Change Password';
    this.exclude = ['persist'];

    const password = this.fb.control('', [Validators.required, Validators.minLength(8)]);

    this.form = this.fb.group({
      oldPassword: this.fb.control('', [Validators.required, Validators.minLength(8)]),
      newPassword: password,
      retypeNew: this.fb.control('', [Validators.required, Validators.minLength(8)])
    }, { validator: this.passwordMatchValidator });

    this.exclude = [];
    this.formActionBtns = [
      {
        type: 'a',
        class: 'forgot-pass',
        label: 'Cancel',
        action: () => {
          this.location.back();
        }
      },
      {
        type: 'button',
        class: 'login-submit-btn',
        label: 'Save',
        action: ''
      }
    ];
  }

  send() {
    this.validateInput();
    if (this.form.valid && this.allValidationPassed) {
      this.userService.changePassword(this.form.value['oldPassword'],
                                      this.form.value['newPassword']).subscribe(() => {
        localStorage.removeItem('customer');
        this.store.dispatch(forceLogout());
        this.router.navigate(['/login']);
        this.snackBar.open('Password have been changed. Please log in again.');
      });
    } else {
      this.snackBar.open('Passwords do not match');
    }
  }

  passwordMatchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const password = control.get('newPassword');
    const confirmPassword = control.get('retypeNew');

    if (!password || !confirmPassword) {
      return null;
    }

    return password.value === confirmPassword.value || !confirmPassword.value ? null : { 'passwordMismatch': true };
  }

  private validateInput() {
    this.validationDone = true;
    const password = this.form.get('newPassword').value;
    this.validationError.character = password.length >= 8;
    this.validationError.number = password.match(/\d+/g) !== null;
    this.validationError.upperCase = password.match(/[A-Z]/) !== null;
    this.validationError.specialCharacter = password.match(/[^A-Za-z0-9]/) !== null;
    const keys = Object.keys(this.validationError);
    this.allValidationPassed = keys.every(key => this.validationError[key]);
  }

}
